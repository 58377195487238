import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import { changeActivePage, setBackPage } from '../redux/general/data';
import Footer from './Footer.js'

class Results extends Component {

    nextPage() {
        this.props.dispatch(setBackPage("results"))
        this.props.dispatch(changeActivePage("signUp"))
    }

    previousPage() {
        this.props.dispatch(changeActivePage("behavior"))
    }


    render() {
        return (
            <div className="view-wrapper">
                <div className="column align-center">
                    <h1 >Results</h1>
                    <h3>Grade: </h3>
                    <h1 className={"reportCard score-" + this.props.reportGrade}>{this.props.reportGrade}</h1>
                </div>
                <div className="results-container">
                    <div className="results-section">
                        {(!!this.props.userNumber || !!this.props.userResult || !!this.props.userOpportunity) &&
                            <p className="title">
                                Audience</p>}
                        {!!this.props.userNumber &&
                            <p> Users: <span className="result">{this.props.userNumber}</span></p>}
                        {!!this.props.userResult &&
                            <p> Your users spend <span className="result">{this.props.userResult}</span> amount of time on your site.</p>}
                        {!!this.props.userOpportunity &&
                            <p> Your top opportunity is to <span className="result">{this.props.userOpportunity}</span></p>}
                    </div>
                    <div className="results-section">
                        {(!!this.props.topLandingPage || !!this.props.topDropoffPage || !!this.props.behaviorOpportunity) &&
                            <p className="title">
                                Behavior</p>}
                        {!!this.props.topLandingPage &&
                            <p> Most popular landing page: <span className="result">{this.props.topLandingPage}</span></p>}
                        {!!this.props.topDropoffPage &&
                            <p> You lose the highest number of visitors on your <span className="result">{this.props.topDropoffPage}</span> page</p>}
                        {!!this.props.behaviorOpportunity &&
                            <p> Your top opportunity is to <span className="result">{this.props.behaviorOpportunity}</span></p>}
                    </div>
                    <div className="results-section">
                        {(!!this.props.mobileNumber || !!this.props.mobileResult || !!this.props.mobileOpportunity) &&
                            <p className="title">
                                Device</p>}
                        {!!this.props.mobileNumber &&
                            <p> Mobile users: <span className="result">{this.props.mobileNumber}%</span></p>}
                        {!!this.props.mobileResult &&
                            <p> A <span className="result">{this.props.mobileResult}</span> number of people access your site via mobile.</p>}
                        {!!this.props.mobileOpportunity &&
                            <p> Your top opporutnity is to <span className="result">{this.props.mobileOpportunity}</span></p>}
                    </div>
                    <div className="results-section">
                        {(!!this.props.topTrafficSource || !!this.props.channelForImprovement || !!this.props.channelOpportunity) &&
                            <p className="title">
                                Acquisition</p>}
                        {!!this.props.topTrafficSource &&
                            <p> Top source of traffic: <span className="result">{this.props.topTrafficSource}</span></p>}
                        {!!this.props.channelForImprovement &&
                            <p> You have opportunities to increase traffic through your <span className="result">{this.props.channelForImprovement}</span> channel.</p>}
                        {!!this.props.channelOpportunity &&
                            <p> Your top opportunity is <span className="result">{this.props.channelOpportunity}</span></p>}
                    </div>
                    <div className="row home-buttons-container">
                        <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>
                        <button className="main-btn" onClick={() => this.nextPage()}>Next</button>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Results = connect((state) => {
    return {
        backPage: state.data.backPage,
        userNumber: state.data.numberOfUsers,
        reportGrade: state.data.reportGrade,
        userResult: state.data.userResult,
        userOpportunity: state.data.userOpportunity,
        mobileNumber: state.data.mobileNumber,
        mobileResult: state.data.mobileResult,
        mobileOpportunity: state.data.mobileOpportunity,
        topTrafficSource: state.data.topTrafficSource,
        channelForImprovement: state.data.channelForImprovement,
        channelOpportunity: state.data.channelOpportunity,
        topLandingPage: state.data.topLandingPage,
        topDropoffPage: state.data.topDropoffPage,
        behaviorOpportunity: state.data.behaviorOpportunity,
    }
})(Results);