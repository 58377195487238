import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import { setUserResult, setUserOpportunity, setReportGrade } from '../redux/general/data';
import { goodScore, goodScoreInverse, reportCard, inverseReportCard } from '../utils/utils';


class AudienceResults extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.setState({
            ...this.props
        })
    }

    componentDidUpdate(prevState) {
        if (this.props !== prevState) {
            this.setState({
                ...this.props
            })
        }
    }

    finalResults(reportGrade, userResult) {
        this.props.dispatch(setUserResult(userResult))
        this.props.dispatch(setReportGrade(reportGrade))

        let userOpportunity = null

        let opportunityObj = {
            'sessionsPerUser': (reportCard(1.5, 1, this.state.sessionsPerUser)),
            'sessionDuration': (reportCard(3, 2, this.state.sessionDuration)),
            'pagesPerSession': (reportCard(3, 2, this.state.pagesPerSession)),
            'bounceRate': (inverseReportCard(40, 70, this.state.bounceRate)),
            'returningVisitors': (reportCard(50, 30, ((this.state.returningVisitors / this.state.users) * 100)))
        }

        const worstResult = Object.keys(opportunityObj).reduce((a, b) => opportunityObj[a] > opportunityObj[b] ? a : b)

        if (worstResult === 'sessionsPerUser' || worstResult === 'sessionDuration') userOpportunity = "add a short video or animation to give the visitor the information they're missing when they arrive."
        if (worstResult === 'pagesPerSession') userOpportunity = "define your sales funnel, and add buttons within each page that take the user to the next page in the funnel."
        if (worstResult === 'bounceRate') userOpportunity = "put a Call To Action on your landing page, i.e. 'Buy Now' or 'Learn More', that tells your users what to do when they arrive."
        if (worstResult === 'returningVisitors') userOpportunity = "add a form for people to sign up to your mailing list, then prompt visitors to return to your site via your email newsletter."

        this.props.dispatch(setUserOpportunity(userOpportunity))

    }

    render() {
        let reportScore = 3
        let reportGrade = "C"
        let userResult = "reasonable"
        const answers = [this.state.sessionsPerUser, this.state.sessionDuration, this.state.pagesPerSession, this.state.bounceRate, this.state.returningVisitors]

        reportScore = (reportCard(1.5, 1, this.state.sessionsPerUser) +
            reportCard(3, 2, this.state.sessionDuration) +
            reportCard(3, 2, this.state.pagesPerSession) +
            inverseReportCard(40, 70, this.state.bounceRate) +
            reportCard(50, 30, this.state.returningVisitors)) / answers.filter(item => !!item).length
        if (Math.round(reportScore) === 5) reportGrade = "F"; userResult = "a poor"
        if (Math.round(reportScore) === 4) reportGrade = "D"; userResult = "an insufficient"
        if (Math.round(reportScore) === 3) reportGrade = "C"
        if (Math.round(reportScore) === 2) reportGrade = "B"; userResult = "a good"
        if (Math.round(reportScore) === 1) reportGrade = "A"; userResult = "an excellent"

        this.finalResults(reportGrade, userResult)

        return (
            <div>
                {Object.keys(this.state).length >= 4 && reportGrade &&
                    <div className="column align-center">
                        <h3>Your grade:</h3>
                        <h1 className={"reportCard score-" + reportGrade}>{reportGrade}</h1>
                    </div>
                }

                {this.state.users && <p>In the past month, <span className="result">{this.state.users}</span> people have visited your website. </p>}

                {this.state.sessionsPerUser && <p>On average, each user has visited your website <span className="result">{this.state.sessionsPerUser}</span> times. {goodScore(1.5, 1, this.state.sessionsPerUser)}</p>}

                {this.state.sessionDuration && <p>They stayed for an average of <span className="result">{this.state.sessionDuration}</span> minutes. Unless they are arriving and immediately buying, you generally want visitors (especially new visitors) to stay for several minutes. {goodScore(3, 2, this.state.sessionDuration)}</p>}

                {this.state.pagesPerSession && <p>They visited <span className="result">{this.state.pagesPerSession}</span> pages. Unless you have a one-page site, you generally need people to visit a couple of pages to go through your sales funnel and convert to a buyer. {goodScore(3, 2, this.state.pagesPerSession)}</p>}

                {this.state.bounceRate && <p>For <span className="result">{this.state.bounceRate}%</span> of these visits, the user bounced as soon as they arrived on your site. This means they left before they explored a new page in the site. {goodScoreInverse(40, 70, this.state.bounceRate)}</p>}

                {this.state.returningVisitors && this.state.users && <p><span className="result">{this.state.users - this.state.returningVisitors}</span> of your users are new and <span className="result">{this.state.returningVisitors}</span> have been there before. It's generally better for business if you have a fairly high number of returning visitors - as these are your top customers. {goodScore(50, 30, this.state.returningVisitors)}</p>
                }

                <h3 className="actions">Actions for you:</h3>
                {this.state.bounceRate || this.state.returningVisitors || this.state.sessionDuration || this.state.pagesPerSession ? <div>
                    {this.state.bounceRate > 41 &&
                        <div>
                            <p>You <span className="result">{this.state.bounceRate > 70 && <span className="bad">drastically</span>}</span> need to improve your <span className="result">bounce rate</span>. High bounce rates occur when the user doesn't find what they're looking for in the first 2 seconds of landing on your website. They're calculated as a user landing on your site and not moving on to another page. <br />
                        Try putting a Call To Action on your landing page, i.e. 'Buy Now' or 'Learn More', that tells your users what to do when they arrive.<br /> <button className="show-btn disabled" type="button" title="Coming soon"></button>
                                <br />
                        If you have a <span className="result">single page site</span>, you need to add manual Analytics triggers to track user behavior, otherwise Analytics will count everything as a bounce.<br />
                                <button className="show-btn disabled" type="button" title="Coming soon"></button></p>

                        </div>}

                    {this.state.returningVisitors && <p>
                        To <span className="result">increase your number of returning visitors</span>, try adding a form for people to sign up to your mailing list. It's generally best to offer to give away something free in exchange for the sign up, i.e. 'Sign up to receive your free tutorial!' Once you have sign ups, you can prompt visitors to return to your site via your email newsletter.
                   <br /> <button className="show-btn disabled" type="button" title="Coming soon"></button>
                    </p>
                    }

                    {this.state.sessionDuration <= 2 && <p>
                        To <span className="result">increase the amount of time </span>people spend on your site, ask someone who's never been there what information is missing when they arrive. Try adding a short video or animation to give the visitor the information they're missing. Keep it under 30 seconds, and you can reuse the clip as a social media ad.<br /> <button className="show-btn disabled" type="button" title="Coming soon"></button>
                    </p>}
                    {this.state.pagesPerSession <= 2 && <p>
                        To <span className="result">increase the amount of pages they look at</span>, try defining your sales funnel - the 1-3 pages you want them to look at as they decide whether to meet your goal (usually to buy something or contact you). On each of these pages, add a button within the page that takes them to the next page in the funnel.<br /><button className="show-btn disabled" type="button" title="Coming soon"></button>
                    </p>}
                </div> :
                    Object.values(this.state).length <= 3 ? <p>No actions for you - Please input more data.</p> :
                        <p> No actions for you - let's keep looking!</p>}
                <p></p>
            </div>
        );
    }
}

export default AudienceResults = connect()(AudienceResults);