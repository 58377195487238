import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import { changeActivePage, setBackPage } from '../redux/general/data';

class Footer extends Component {
    constructor(props) {
        super(props)

        this.signUp = this.signUp.bind(this)
    }

    signUp(currentPage) {
        this.props.dispatch(setBackPage(currentPage))
        this.props.dispatch(changeActivePage('signUp'))

    }

    render() {
        return (
            <div className="column align-center footer">
                <button className="inv-btn" onClick={() => this.signUp(this.props.activePage)}>
                    <h3>Sign up to the mailing list</h3>
                </button>
            </div>
        );
    }
}

export default Footer = connect((state) => {
    return {
        activePage: state.data.activePage
    }
})(Footer);
