import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';

class ProgressBar extends Component {
    constructor(props) {
        super(props);

        this.oneIsActive = this.oneIsActive.bind(this)

        this.state = {}
    }

    componentDidMount() {
        if (this.props.activePage !== "behavior") {
            return window.setTimeout(() => {
                this.setState({
                    goActive: true
                })
            }, 100);
        }

        this.setState({
            goActive: true
        })
    }

    oneIsActive() {
        return this.state.goActive ? "progress-active" : ""
    }

    twoIsActive() {
        return this.props.activePage === "audience" ? "" : "progress-active"
    }
    threeIsActive() {
        return this.props.activePage === "audience" || this.props.activePage === "device" ? "" : "progress-active"
    }
    fourIsActive() {
        return this.props.activePage === "behavior" ? "progress-active" : ""
    }

    render() {
        return (
            <div className="row full-width progress-line">
                <div className={"progress-section " + this.oneIsActive()}></div>
                <div className={"progress-section " + this.twoIsActive()}></div>
                <div className={"progress-section " + this.threeIsActive()}></div>
                <div className={"progress-section " + this.fourIsActive()}></div>

            </div>
        );
    }
}

export default ProgressBar = connect((state) => {
    return {
        activePage: state.data.activePage
    }
})(ProgressBar);