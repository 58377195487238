import React, { Component } from 'react';
import { usersFromPercent } from '../utils/utils';
import { connect } from 'react-redux';
import '../home.css';
import { changeActivePage } from '../redux/general/data';
import DeviceResults from './DeviceResults'
import mobile from '../images/mobile.gif'
import home from '../images/home.gif'
import mobileIcon from '../images/mobile-icon.svg'
import desktopIcon from '../images/desktop-icon.svg'
import tabletIcon from '../images/tablet-icon.svg'

class Device extends Component {
    constructor(props) {
        super(props)
        this.analysisSection = React.createRef()

        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.showGif = this.showGif.bind(this)
        this.hideGif = this.hideGif.bind(this)
        this.renderDevice = this.renderDevice.bind(this)

        this.state = {}
    }

    componentDidMount() {
        this.setState({
            analysisSectionActive: false,
        })
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit(event);
        }
    }

    handleChange(e) {
        e.persist()

        var stateObject = function () {
            let returnObj = {};
            returnObj[this.target.name] = this.target.value;
            return returnObj;
        }.bind(e)();

        this.setState(stateObject);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.submitError && Object.values(this.state).length <= 2) return this.setState({ submitError: true, analysisSectionActive: false })

        if (Object.values(this.state).length <= 1) return this.setState({ submitError: true, analysisSectionActive: false })

        if ((Number(this.state.tablet) + Number(this.state.mobile) + Number(this.state.desktop)) !== 100) this.setState({ addError: true })

        this.setState({
            submitError: false,
            analysisSectionActive: true,
        })

        this.hideGif()

        window.setTimeout(() => {
            this.scrollToAnalysis()
        }, 100);
    }

    scrollToAnalysis = () => window.scrollTo({
        top: this.analysisSection.current.offsetTop,
        left: 0,
        behavior: 'smooth'
    })

    nextPage() {
        this.props.dispatch(changeActivePage("acquisition"))
    }

    previousPage() {
        this.props.dispatch(changeActivePage("audience"))
    }

    showGif(gif) {
        let source = home
        if (gif === "mobile") source = mobile
        if (gif === "home") source = home

        this.setState({
            showGif: true,
            gifSource: source,
        })

        document.body.classList.add("noscroll")
    }

    hideGif() {
        this.setState({
            showGif: false,
        })
        document.body.classList.remove("noscroll")
    }


    renderDevice() {
        const combinedMobile = (Number(this.state.mobile) || 0) + (Number(this.state.tablet) || 0)

        return <div>
            <div>
                {!!this.state.mobile && <p> <span className="result">{this.state.mobile}</span>% of users came to your site on a mobile phone.</p>}
                {!!this.state.tablet && <p> <span className="result">{this.state.tablet}</span>% of users came to your site using a tablet.</p>}
                {(this.state.mobile || this.state.tablet) && <p>In total, that's {usersFromPercent(combinedMobile, this.props.numberOfUsers)} visitors.</p>}
                <p>In 2017, mobile internet use surpassed computer-based internet for the first time. Today over 52% of internet use occurs via a mobile phone, with numbers rising fairly steadily every year.</p>
                <p>If people have a negative experience on mobile, they're <a href="https://www.thinkwithgoogle.com/intl/en-gb/advertising-channels/mobile/few-tips-speed-your-mobile-site-and-tools-test-it/" target="_blank" rel="noopener noreferrer">62% less likely</a> to make a purchase with you next time.</p>
                <p>Since 2018 Google search prefers mobile-friendly sites. Sites that aren't mobile friendly can have their search ranking slashed.</p>
            </div>
            <h3 className="actions">Actions for you:</h3>
            <div>
                <p>Review your mobile site to ensure mobile visitors have a good experience there.</p>
                <p>Mobile review checklist:</p>

                <p><input type="checkbox" />Your website looks good on mobile</p>
                <p><input type="checkbox" />Text is big enough to read without zooming in</p>
                <p><input type="checkbox" />User doesn't need to rotate their phone horizontally</p>
                <p><input type="checkbox" />Customer can access all the buttons and links - they far enough apart so a finger can press just one</p>
                <p><input type="checkbox" />There are no annoying pop-ups that block access to the site</p>
                <p><input type="checkbox" />Site does not require Flash. iPhones don't like Flash.</p>
                <p><input type="checkbox" />Site looks good on both iPhone and Android, phones and tablets, across a range of different browsers and screen sizes</p>
                <p><input type="checkbox" />Site loads on mobile data (3G, 4G or 5G) in under 3 seconds. More than 3 seconds <a href="https://www.thinkwithgoogle.com/marketing-resources/data-measurement/mobile-page-speed-new-industry-benchmarks/" target="_blank" rel="noopener noreferrer">makes the probability of your visitor bouncing increase 32%</a>.</p>
                <p><input type="checkbox" />Sales operations and/or contact forms work easily</p>
                <br />
                <p>Check whether Google thinks your site is mobile friendly by <a href="https://search.google.com/test/mobile-friendly" target="_blank" rel="noopener noreferrer"> entering the url here.</a></p>
                <p>If your site is too slow, improve the load time of your mobile site.<br /><button className="show-btn disabled" type="button" title="Coming soon"></button></p>
                <p>If your site doesn't look good, make your site mobile responsive.<br /><button className="show-btn disabled" type="button" title="Coming soon"></button></p>
            </div>
        </div>
    }

    render() {
        return (
            <div className="view-wrapper">
                <div className="column align-center">
                    <h1>Device</h1>
                    <h3>How are people coming to your website?</h3>
                </div>
                <div className="row">
                    <form className="GA-form" onSubmit={this.handleSubmit} >
                        <div className="instructions">
                            <div className="instruction-row">
                                <p>1. Go to Home in the left-hand column.</p>
                                <button className="play-btn" type="button" onClick={() => this.showGif("home")}>{">"}</button>
                            </div>
                            <div className="instruction-row">
                                <p>2. Scroll down to 'Top Devices' and change the timeframe to 90 days.</p>
                                <button className="play-btn" type="button" onClick={() => this.showGif("mobile")}>{">"}</button>
                            </div>
                            <div className="row form align-base">
                                <label>
                                    <div><img alt="desktop" className="device-icon" src={desktopIcon} /> Desktop:</div>
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="desktop" />
                                </label>%</div>
                            <div className="row form align-base">
                                <label>
                                    <div><img alt="tablet" className="device-icon" src={tabletIcon} /> Tablet:</div>
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="tablet" />
                                </label>%</div>
                            <div className="row form align-base">
                                <label>
                                    <div> <img alt="mobile" className="device-icon" src={mobileIcon} /> Mobile:</div>
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="mobile" />
                                </label>%</div>
                            {this.state.submitError ?
                                <div className="column">
                                    <div className="row submit-btn-container">
                                        {!this.state.analysisSectionActive && <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>}
                                        <div>
                                            <button className="main-btn error" type="submit" value="Submit">Submit</button>
                                        </div>
                                    </div>
                                    <p className="submitError">Please input some more information</p>
                                </div>
                                :
                                <div className="column">
                                    <div className="row submit-btn-container">
                                        {!this.state.analysisSectionActive && <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>}
                                        <button className="main-btn" type="submit" value="Submit">Submit</button>
                                    </div>
                                    {this.state.addError && <p className="addError">Careful - Your numbers don't add up to 100%</p>}
                                </div>
                            }

                            <div ref={this.analysisSection}>
                                {this.state.analysisSectionActive &&
                                    <div className="analysis-section" id="analysis-section" >
                                        <h2>Device Analysis</h2>
                                        <DeviceResults
                                            {...this.state}
                                        />
                                        <div className="row home-buttons-container">
                                            <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>
                                            <button className="main-btn" onClick={() => this.nextPage()}>Next</button>
                                        </div>
                                    </div>
                                }</div>
                        </div>
                    </form>
                    {this.state.showGif &&
                        <div className="gif-container">
                            <button className="close" onClick={() => this.hideGif()}>X</button>
                            <img className="gif-player" src={this.state.gifSource} alt="understand google analytics free tool" />
                        </div>
                    }
                </div>
            </div >
        );
    }
}


export default Device = connect()(Device);