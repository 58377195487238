import * as actionTypes from "./actionTypes";

// /**GENERAL ACTIONS **/
export function changeActivePage(page) {
    return {
        type: actionTypes.ACTIVE_PAGE,
        activePage: page
    }
}
export function setBackPage(page) {
    return {
        type: actionTypes.SET_BACK_PAGE,
        backPage: page
    }
}
export function setUserNumber(userNumber) {
    return {
        type: actionTypes.SET_USER_NUMBER,
        numberOfUsers: userNumber
    }
}
export function setReportGrade(grade) {
    return {
        type: actionTypes.SET_REPORT_GRADE,
        grade: grade
    }
}
export function setUserResult(result) {
    return {
        type: actionTypes.SET_USER_RESULT,
        result: result
    }
}
export function setUserOpportunity(opportunity) {
    return {
        type: actionTypes.SET_USER_OPPORTUNITY,
        opportunity: opportunity
    }
}
export function setMobileUserNumber(mobileUserNumber) {
    return {
        type: actionTypes.SET_MOBILE_NUMBER,
        mobileNumber: mobileUserNumber
    }
}
export function setMobileResult(result) {
    return {
        type: actionTypes.SET_MOBILE_RESULT,
        result: result
    }
}
export function setMobileOpportunity(opportunity) {
    return {
        type: actionTypes.SET_MOBILE_OPPORTUNITY,
        opportunity: opportunity
    }
}
export function setTopTrafficSource(source) {
    return {
        type: actionTypes.SET_TOP_TRAFFIC,
        source: source
    }
}
export function setChannelImprovement(channel) {
    return {
        type: actionTypes.SET_CHANNEL_IMPROVEMENT,
        channel: channel
    }
}
export function setChannelOpportunity(opportunity) {
    return {
        type: actionTypes.SET_CHANNEL_OPPORTUNITY,
        opportunity: opportunity
    }
}
export function setTopLandingPage(page) {
    return {
        type: actionTypes.SET_LANDING_PAGE,
        page: page
    }
}
export function setDropoffPage(page) {
    return {
        type: actionTypes.SET_DROPOFF_PAGE,
        page: page
    }
}
export function setBehaviorOpportunity(opportunity) {
    return {
        type: actionTypes.SET_BEHAVIOR_OPPORTUNITY,
        opportunity: opportunity
    }
}
