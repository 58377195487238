import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import { setTopLandingPage, setDropoffPage, setBehaviorOpportunity } from '../redux/general/data';


class BehaviorResults extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.setState({
            ...this.props
        })
    }

    componentDidUpdate(prevState) {
        if (this.props !== prevState) {
            this.setState({
                ...this.props
            })
        }
    }

    largestPageDropoffs(behaviors) {
        //MK use const worstResult = Object.keys(opportunityObj).reduce((a, b) => opportunityObj[a] > opportunityObj[b] ? a : b)
        if (behaviors) {
            const one = behaviors.behaviorOne.dropoffs
            const two = behaviors.behaviorTwo.dropoffs
            const three = behaviors.behaviorThree.dropoffs

            if (Math.max(one, two, three) == one) return behaviors.behaviorOne
            if (Math.max(one, two, three) == two) return behaviors.behaviorTwo
            if (Math.max(one, two, three) == three) return behaviors.behaviorThree
        }
    }

    finalResults() {
        const topPage = {
            [this.state.behaviorFlowOne]: this.state.behaviorOneThrough,
            [this.state.behaviorFlowTwo]: this.state.behaviorTwoThrough,
            [this.state.behaviorFlowThree]: this.state.behaviorThreeThrough,
        }
        console.log('topPage', topPage)
        const bestResult = Object.keys(topPage).reduce((a, b) => topPage[a] > topPage[b] ? a : b)

        this.props.dispatch(setTopLandingPage(bestResult))

        const lossPage = {
            [this.state.behaviorFlowOne]: this.state.behaviorOneDropoffs,
            [this.state.behaviorFlowTwo]: this.state.behaviorTwoDropoffs,
            [this.state.behaviorFlowThree]: this.state.behaviorThreeDropoffs,
        }
        console.log('topPage', lossPage)
        const worstResult = Object.keys(lossPage).reduce((a, b) => lossPage[a] > lossPage[b] ? a : b)

        this.props.dispatch(setDropoffPage(worstResult))

        const behaviorOpp = "think about what the user is looking for when they come to this page, and ensure the information is immediately available to them."

        this.props.dispatch(setBehaviorOpportunity(behaviorOpp))

    }

    render() {
        const behaviors = {
            behaviorOne: {
                page: this.state.behaviorFlowOne,
                through: this.state.behaviorOneThrough,
                dropoffs: this.state.behaviorOneDropoffs,
            },
            behaviorTwo: {
                page: this.state.behaviorFlowTwo,
                through: this.state.behaviorTwoThrough,
                dropoffs: this.state.behaviorTwoDropoffs,
            },
            behaviorThree: {
                page: this.state.behaviorFlowThree,
                through: this.state.behaviorThreeThrough,
                dropoffs: this.state.behaviorThreeDropoffs,
            }
        }

        this.finalResults()

        return (
            <div>
                {this.state.behaviorThreeDropoffs && <div>
                    <p>
                        The majority of your users land on your <span className="result">{this.state.behaviorFlowOne}</span> page. They go from there to the <span className="result">{this.state.behaviorFlowTwo}</span> page, then head to the <span className="result">{this.state.behaviorFlowThree}</span> page.
                </p>
                    <p>
                        Your <span className="result">{this.largestPageDropoffs(behaviors).page}</span> page loses the highest number of visitors. Unless this is your 'Contact' or 'Successful purchase' page, this is a problem. We want to keep people on your site to get them to your goal.
                </p>
                    <h3 className="actions">Actions for you:</h3>
                    <p>
                        Your <span className="result">{this.largestPageDropoffs(behaviors).page}</span> page lost <span className="result">{this.largestPageDropoffs(behaviors).dropoffs}</span> visitors last month. Let's fix that.
                </p>
                    <p>Review your <span className="result">{this.largestPageDropoffs(behaviors).page}</span> page.</p>
                    <ul>
                        <li>Is the page's purpose clearly stated in the first screenview?</li>
                        <li>Have you thought about what the user is looking for when they come to this page?</li>
                        <li>Is it immediately clear where users should go to find the information they need?</li>
                        <li>How does this page look on mobile?</li>
                        <li>Can the page design be simpler?</li>
                        <li>Can you reduce the amount of text?</li>
                        <li>Are there videos or sounds autoplaying that you can turn off?</li>
                        <li>Have you tested to see what the page looks like on every browser?</li>
                    </ul>
                    <p>
                        Your <span className="result">{this.largestPageDropoffs(behaviors).page}</span> page had <span className="result">{this.largestPageDropoffs(behaviors).through}</span> visitors flow through to the next page last month. Let's increase that.
                </p>
                    <p>Try putting a 'Call to action' on the first screen of your page that tells users where to go - i.e. a button that says "Buy Now" or "Learn More".
                <br /><button className="show-btn disabled" type="button" title="Coming soon"></button></p>
                </div>}
            </div>
        )
    }


}

export default BehaviorResults = connect()(BehaviorResults);