import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import { setTopTrafficSource, setChannelImprovement, setChannelOpportunity } from '../redux/general/data';
import { arrayToStringConjunctions, usersFromPercent } from '../utils/utils';

class AcquisitionResults extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.setState({
            ...this.props
        })
    }

    componentDidUpdate(prevState) {
        if (this.props !== prevState) {
            this.setState({
                ...this.props
            })
        }
    }

    finalResults(channels) {
        const bestResult = Object.keys(channels).reduce((a, b) => channels[a] > channels[b] ? a : b)

        this.props.dispatch(setTopTrafficSource(bestResult))

        const worstResult = Object.keys(channels).reduce((a, b) => channels[a] < channels[b] ? a : b)

        this.props.dispatch(setChannelImprovement(worstResult))

        let channelOpp
        if (worstResult === "direct") channelOpp = "attach an Urchin Tracking Module (UTM) to the links you share so that Google can see how people follow them to your website."
        if (worstResult === "organic") channelOpp = "improve the SEO on your website."
        if (worstResult === "paid") channelOpp = "run a Google Adwords campaign that links back to your site, and measure the results."
        if (worstResult === "email") channelOpp = "use a mailing list software to send out a newsletter that links back to your site."
        if (worstResult === "referral") channelOpp = "offer another website content that links back to your site."
        if (worstResult === "social") channelOpp = "run a Facebook ad from your Facebook business page that links back to your site."

        this.props.dispatch(setChannelOpportunity(channelOpp))

    }

    render() {
        const channels = {
            direct: this.state.direct,
            organic: this.state.organic,
            paid: this.state.paid,
            email: this.state.email,
            referral: this.state.referral,
            social: this.state.social
        }
        const channelsForImprovement = Object.keys(channels).filter(channel => {
            if (channels[channel] < 10 || !channels[channel]) return channel
            return ''
        })

        this.finalResults(channels)

        return (
            <div>
                {this.props.numberOfUsers ? <div>
                    {this.state.direct >= 1 && <p><span className="result">{this.state.direct}</span>% of your traffic came from Direct channels. 'Direct' means a user came to your site in a way that can't be tracked by Google. It could be that they are using an adblocker, or that they clicked a link that Google doesn't track. At the beginning of this form you wrote that you had <span className="result">{this.props.numberOfUsers}</span> users this month. That means <span className="result">{usersFromPercent(Number(this.state.direct), this.props.numberOfUsers)}</span> people came to your site through Direct channels.</p>}

                    {this.state.organic >= 1 && <p><span className="result">{this.state.organic}%</span> of your traffic came from Organic sources. This means you came up organically in the search results of <span className="result">{usersFromPercent(this.state.organic, this.props.numberOfUsers)}</span> users. "Organically" means you didn't have to pay to be in the search results, your site came up on its own.</p>}

                    {channels.paid >= 1 ? <p><span className="result">{usersFromPercent(this.state.paid, this.props.numberOfUsers)}</span> users came to your site through a paid channel, such as a Google Adwords campaign.</p> : <p>No users came from paid channels. Either you aren't running any Google Adwords campaigns, or they aren't working.</p>}

                    {this.state.email >= 1 && <p>Emails brought <span className="result">{usersFromPercent(this.state.email, this.props.numberOfUsers)}</span> users to your site.</p>}

                    {this.state.referral >= 1 && <p><span className="result">{this.state.referral}</span>% of your traffic is Referral traffic, which means <span className="result">{usersFromPercent(this.state.referral, this.props.numberOfUsers)}</span> users clicked a link to get to your site - i.e. in the signature of an email. Referral links are links that Google can track.</p>}

                    {this.state.social >= 1 && <p>Social media activity brought <span className="result">{usersFromPercent(this.state.social, this.props.numberOfUsers)}</span> users to your site.</p>} <br />

                    {!!Object.values(channels).filter(item => !!item).length && <p>There are no right and wrong ways for users to come to your site.</p>}

                    {!!channelsForImprovement.length && <p>However, it looks like there are opportunities to increase traffic to your site through your <span className="result">{arrayToStringConjunctions(channelsForImprovement)}</span> {channelsForImprovement.length === 1 ? "channel" : "channels"}.</p>}
                    <br />
                    {this.state.highestDailyUsers && <p>Last month, the highest number of users you had on a single given day was <span className="result">{this.state.highestDailyUsers}</span> users.</p>}
                    <br />
                    <h3 className="actions">Actions for you:</h3>
                    {!!Object.values(channelsForImprovement).filter(item => !!item).length ?
                        <div style={{}}>
                            {channelsForImprovement.includes('social') && <div>
                                <p>Last month
                                {this.state.social >= 1 ?
                                        <span> you acquired <span className="reasonable">{usersFromPercent(this.state.social, this.props.numberOfUsers)}</span> Let's attempt to increase that 10% to <span className="good">{(Math.round(usersFromPercent(this.state.referral, this.props.numberOfUsers)) * 1.1).toFixed()}</span> this month. </span> : <span> you didn't acquire any </span>}
                                users from social media activity.</p>

                                <p>To get more info on which social media is leading people to your site, check out your Source / Medium data.
                            <button className="play-btn" type="button" onClick={() => this.showGif("sources", 1)}>{">"}</button></p>

                                <p>To improve traffic through your social channels, try running a Facebook ad from your Facebook business page.<br /><button className="show-btn disabled" type="button" title="Coming soon"></button></p>

                            </div>}
                            {channelsForImprovement.includes('paid') && <p>
                                {channels.paid >= 1 ?
                                    <span>Only <span className="result">{channels.paid}%</span> of your </span> :
                                    <span>No</span>} traffic came from paid advertising. To improve traffic through paid channels, try running a Google Adwords campaign and measuring the results. <br /><button className="show-btn disabled" type="button" title="Coming soon"></button>
                            </p>}

                            <p>
                                {this.state.organic >= 1 ? <span>This month you acquired <span className="reasonable">{usersFromPercent(this.state.organic, this.props.numberOfUsers)}</span> users organically from search results. Let's attempt to increase that 10% to <span className="good">{Math.round(usersFromPercent(this.state.organic, this.props.numberOfUsers) * 1.1).toFixed()}</span> this month. </span> : <span>This month you didn't acquire any users from social media.</span>}<br />
                                To improve traffic through organic search, try improving the SEO on your website. <br /><button className="show-btn disabled" type="button" title="Coming soon"></button>
                            </p>
                            {channelsForImprovement.includes('email') && <p>
                                {channels.email >= 1 ?
                                    <span>Only <span className="result">{channels.email}%</span> of your </span> :
                                    <span>No</span>} traffic came from email campaigns. To improve traffic through your email channels, try using a mailing list software to send out a newsletter that links back to your site. <br />
                                <button className="show-btn disabled" type="button" title="Coming soon"></button>
                            </p>}
                            {this.state.direct && <p>If you want to gain more visibility into your Direct traffic, you can try creating an Urchin Tracking Module (UTM) - a piece of code that you attach to the links you share so that Google can see how people follow them to your website.<br />
                                <button className="show-btn disabled" type="button" title="Coming soon"></button>
                            </p>}
                            {channelsForImprovement.includes('referral') && <p>
                                {channels.referral >= 1 ?
                                    <span>Only <span className="result">{channels.referral}%</span> of your </span> :
                                    <span>No</span>} traffic was referred from other sources. To improve traffic through your referral channels, try offering another website content that links back to your site. <br />
                                <button className="show-btn disabled" type="button" title="Coming soon"></button>
                            </p>}
                        </div> :
                        <p> No actions for you. Let's keep looking.</p>
                    }
                </div>
                    : <div><p>To show you this information, we need to know your number of monthly users. Start again to input user numbers.</p>
                        <button className="main-btn">Start again</button>
                    </div>
                }
            </div>
        )
    }


}

export default AcquisitionResults = connect((state) => {
    return {
        numberOfUsers: state.data.numberOfUsers,
    }
})(AcquisitionResults);