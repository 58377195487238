import React, { Component } from 'react';
import './home.css'

class PageOne extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        return (
            <div className="page-wrapper">
                <a href={"/"}>Home</a>
                <h1>Page 1</h1>
            </div>
        );
    }
}


export default PageOne;