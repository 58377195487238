import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import Home from './Home'
import PageOne from './PageOne'
import NotFound from './NotFound'

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidUpdate() {
        window.scrollTo(0, 0)
    }

    render() {

        return (
            <div>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/pageOne" component={PageOne} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        );
    }
}


export default App;