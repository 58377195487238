import React from 'react';
import ReactDOM from 'react-dom';
import { combineReducers } from 'redux';
import './index.css';
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from 'history';
import thunk from "redux-thunk";
import { ConnectedRouter, connectRouter, routerMiddleware } from "connected-react-router";
import App from "./App";
import data from './redux/general/reducer'

const history = createBrowserHistory();
const middleware = routerMiddleware(history);

// Redux combine reducers
const rootReducer = combineReducers({
    router: connectRouter(history),
    data: data
});

const store = createStore(
    connectRouter(history)(rootReducer),
    compose(applyMiddleware(middleware, thunk)));

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App store={store} />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));