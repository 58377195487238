import React, { Component } from '../../node_modules/react';
import { connect } from '../../node_modules/react-redux';
import '../home.css';
import { changeActivePage } from '../redux/general/data'
import AcquisitionResults from './AcquisitionResults'
import { usersFromPercent, arrayToStringConjunctions } from '../utils/utils';
import overview from '../images/acquisition-overview.gif'
import sourcemedium from '../images/sourcemedium.gif'
import sources from '../images/sources.gif'
import channels from '../images/top-channels.gif'
import users from '../images/usersdotline.gif'

class Acquisition extends Component {
    constructor(props) {
        super(props)
        this.analysisSection = React.createRef()

        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.showGif = this.showGif.bind(this)
        this.hideGif = this.hideGif.bind(this)
        this.renderAquisition = this.renderAquisition.bind(this)

        this.state = {}
    }

    componentDidMount() {
        this.setState({
            analysisSectionActive: false,
            showGif: false,
            gifSource: null,
        })
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit(event);
        }
    }

    handleChange(e) {
        e.persist()

        var stateObject = function () {
            let returnObj = {};
            returnObj[this.target.name] = this.target.value;
            return returnObj;
        }.bind(e)();

        this.setState(stateObject);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (Object.keys(this.state).length <= 3) {
            return this.setState({
                analysisSectionActive: false,
                submitError: true
            })
        }

        if ((Number(this.state.direct) + Number(this.state.organic) + Number(this.state.paid) + Number(this.state.email) + Number(this.state.referral) + Number(this.state.social)) !== 100) this.setState({ addError: true })

        this.setState({
            analysisSectionActive: true,
            submitError: false,
        })

        this.hideGif()

        window.setTimeout(() => {
            this.scrollToAnalysis()
        }, 100);

    }

    scrollToAnalysis = () => window.scrollTo({
        top: this.analysisSection.current.offsetTop,
        left: 0,
        behavior: 'smooth'
    })

    nextPage() {
        this.props.dispatch(changeActivePage("behavior"))
    }

    previousPage() {
        this.props.dispatch(changeActivePage("device"))
    }

    showGif(gif, section) {
        let source = overview
        if (gif === "overview") source = overview
        if (gif === "sourcemedium") source = sourcemedium
        if (gif === "sources") source = sources
        if (gif === "channels") source = channels
        if (gif === "users") source = users

        this.setState({
            showGif: true,
            gifSource: source,
            gifSection: section
        })

        document.body.classList.add("noscroll")
    }

    hideGif() {
        this.setState({
            showGif: false,
        })

        document.body.classList.remove("noscroll")
    }

    renderAquisition() {
        const channels = {
            direct: this.state.direct,
            organic: this.state.organic,
            paid: this.state.paid,
            email: this.state.email,
            referral: this.state.referral,
            social: this.state.social
        }
        const channelsForImprovement = Object.keys(channels).filter(channel => {
            if (channels[channel] < 10 || !channels[channel]) return channel
            return ''
        })

        return <div>
            {this.props.numberOfUsers ? <div>
                {this.state.direct >= 1 && <p><span className="result">{this.state.direct}</span>% of your traffic came from Direct channels. 'Direct' means a user came to your site in a way that can't be tracked by Google. It could be that they are using an adblocker, or that they clicked a link that Google doesn't track. At the beginning of this form you wrote that you had <span className="result">{this.props.numberOfUsers}</span> users this month. That means <span className="result">{usersFromPercent(Number(this.state.direct), this.props.numberOfUsers)}</span> people came to your site through Direct channels.</p>}

                {this.state.organic >= 1 && <p><span className="result">{this.state.organic}%</span> of your traffic came from Organic sources. This means you came up organically in the search results of <span className="result">{usersFromPercent(this.state.organic, this.props.numberOfUsers)}</span> users. "Organically" means you didn't have to pay to be in the search results, your site came up on its own.</p>}

                {channels.paid >= 1 ? <p><span className="result">{usersFromPercent(this.state.paid, this.props.numberOfUsers)}</span> users came to your site through a paid channel, such as a Google Adwords campaign.</p> : <p>No users came from paid channels. Either you aren't running any Google Adwords campaigns, or they aren't working.</p>}

                {this.state.email >= 1 && <p>Emails brought <span className="result">{usersFromPercent(this.state.email, this.props.numberOfUsers)}</span> users to your site.</p>}

                {this.state.referral >= 1 && <p><span className="result">{this.state.referral}</span>% of your traffic is Referral traffic, which means <span className="result">{usersFromPercent(this.state.referral, this.props.numberOfUsers)}</span> users clicked a link to get to your site - i.e. in the signature of an email. Referral links are links that Google can track.</p>}

                {this.state.social >= 1 && <p>Social media activity brought <span className="result">{usersFromPercent(this.state.social, this.props.numberOfUsers)}</span> users to your site.</p>} <br />

                {!!Object.values(channels).filter(item => !!item).length && <p>There are no right and wrong ways for users to come to your site.</p>}

                {!!channelsForImprovement.length && <p>However, it looks like there are opportunities to increase traffic to your site through your <span className="result">{arrayToStringConjunctions(channelsForImprovement)}</span> {channelsForImprovement.length === 1 ? "channel" : "channels"}.</p>}
                <br />
                {this.state.highestDailyUsers && <p>Last month, the highest number of users you had on a single given day was <span className="result">{this.state.highestDailyUsers}</span> users.</p>}
                <br />
                <h3 className="actions">Actions for you:</h3>
                {!!Object.values(channelsForImprovement).filter(item => !!item).length ?
                    <div style={{}}>
                        {channelsForImprovement.includes('social') && <div>
                            <p>Last month
                                {this.state.social >= 1 ?
                                    <span> you acquired <span className="reasonable">{usersFromPercent(this.state.social, this.props.numberOfUsers)}</span> Let's attempt to increase that 10% to <span className="good">{(Math.round(usersFromPercent(this.state.referral, this.props.numberOfUsers)) * 1.2).toFixed()}</span> this month. </span> : <span> you didn't acquire any </span>}
                                users from social media activity.</p>

                            <p>To get more info on which social media is leading people to your site, check out your Source / Medium data.
                            <button className="play-btn" type="button" onClick={() => this.showGif("sources", 1)}>{">"}</button></p>

                            <p>To improve traffic through your social channels, try running a Facebook ad from your Facebook business page.<br /><button className="show-btn disabled" type="button" title="Coming soon"></button></p>

                        </div>}
                        {channelsForImprovement.includes('paid') && <p>
                            {channels.paid >= 1 ?
                                <span>Only <span className="result">{channels.paid}%</span> of your </span> :
                                <span>No</span>} traffic came from paid advertising. To improve traffic through paid channels, try running a Google Adwords campaign and measuring the results. <br /><button className="show-btn disabled" type="button" title="Coming soon"></button>
                        </p>}

                        <p>
                            {this.state.organic >= 1 ? <span>This month you acquired <span className="reasonable">{usersFromPercent(this.state.organic, this.props.numberOfUsers)}</span> users organically from search results. Let's attempt to increase that 10% to <span className="good">{(usersFromPercent(this.state.organic, this.props.numberOfUsers) * 1.1).toFixed()}</span> this month. </span> : <span>This month you didn't acquire any users from social media.</span>}<br />
                                To improve traffic through organic search, try improving the SEO on your website. <br /><button className="show-btn disabled" type="button" title="Coming soon"></button>
                        </p>
                        {channelsForImprovement.includes('email') && <p>
                            {channels.email >= 1 ?
                                <span>Only <span className="result">{channels.email}%</span> of your </span> :
                                <span>No</span>} traffic came from email campaigns. To improve traffic through your email channels, try using a mailing list software to send out a newsletter that links back to your site. <br />
                            <button className="show-btn disabled" type="button" title="Coming soon"></button>
                        </p>}
                        {this.state.direct && <p>If you want to gain more visibility into your Direct traffic, you can try creating an Urchin Tracking Module (UTM) - a piece of code that you attach to the links you share so that Google can see how people follow them to your website.<br />
                            <button className="show-btn disabled" type="button" title="Coming soon"></button>
                        </p>}
                        {channelsForImprovement.includes('referral') && <p>
                            {channels.referral >= 1 ?
                                <span>Only <span className="result">{channels.referral}%</span> of your </span> :
                                <span>No</span>} traffic was referred from other sources. To improve traffic through your referral channels, try offering another website content that links back to your site. <br />
                            <button className="show-btn disabled" type="button" title="Coming soon"></button>
                        </p>}
                    </div> :
                    <p> No actions for you. Let's keep looking.</p>
                }
            </div>
                : <div><p>To show you this information, we need to know your number of monthly users. Start again to input user numbers.</p>
                    <button className="main-btn">Start again</button>
                </div>
            }
        </div>
    }

    render() {
        return (
            <div className="view-wrapper">
                <div className="column align-center">
                    <h2>Acquisition</h2>
                    <h3>How are users coming to your website?</h3>
                </div>
                <form className="GA-form" onSubmit={this.handleSubmit} >
                    <div className="instructions">
                        <div className="row">
                            <div className="sectionOne">
                                <div className="instruction-row">
                                    <p>1. Go to Acquisition - Overview in the left-hand column</p>
                                    <button className="play-btn" type="button" onClick={() => this.showGif("overview", 1)}>{">"}</button>
                                </div>
                                <div className="instruction-row">
                                    <p>2. Look at the Top Channels graph</p>
                                    <button className="play-btn" type="button" onClick={() => this.showGif("channels", 1)}>{">"}</button>
                                </div>
                                <div className="row form align-base">
                                    <label>
                                        Direct:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="direct" />
                                    </label>%
                                </div><br />
                                <div className="row form align-base">
                                    <label>
                                        Organic:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="organic" />
                                    </label>%
                                </div><br />
                                <div className="row form align-base">
                                    <label>
                                        Paid:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="paid" />
                                    </label>%</div><br />
                                <div className="row form align-base">
                                    <label>
                                        Email:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="email" />
                                    </label>%</div><br />
                                <div className="row form align-base">
                                    <label>
                                        Referral:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="referral" />
                                    </label>%
                                </div><br />
                                <div className="row form align-base">
                                    <label>
                                        Social:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="social" />
                                    </label>%
                                </div><br />
                                <div className="instruction-row">
                                    <p>3. Look at the Users dot-line graph</p>
                                    <button className="play-btn" type="button" onClick={() => this.showGif("users", 1)}>{">"}</button>
                                </div>
                                <label>
                                    Highest number of daily users (highest point on the graph):
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="highestDailyUsers" />
                                </label><br />
                            </div>
                            {this.state.showGif && this.state.gifSection === 1 &&
                                <div className="section gif-container">
                                    <button className="close" onClick={() => this.hideGif()}>X</button>
                                    <img className="gif-player" src={this.state.gifSource} alt="understand google analytics free tool" />
                                </div>
                            }
                        </div>
                        {this.state.submitError ?
                            <div className="column">
                                <div className="row submit-btn-container">
                                    {!this.state.analysisSectionActive && <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>}
                                    <div>
                                        <button className="main-btn error" type="submit" value="Submit">Submit</button>
                                    </div>
                                </div>
                                <p className="submitError">Please input some data</p>
                            </div>
                            :
                            <div className="column">
                                <div className="row submit-btn-container">
                                    {!this.state.analysisSectionActive && <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>}
                                    <button className="main-btn" type="submit" value="Submit">Submit</button>
                                </div>
                                {this.state.addError && <p className="addError">Careful - Your numbers don't add up to 100%</p>}
                            </div>
                        }

                        <div ref={this.analysisSection}>
                            {this.state.analysisSectionActive &&
                                <div className={"analysis-section"}>
                                    <h2>Acquisition Analysis</h2>
                                    <AcquisitionResults
                                        {...this.state}
                                    />
                                    <div className="row home-buttons-container">
                                        <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>
                                        <button className="main-btn" onClick={() => this.nextPage()}>Next</button>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </form>
            </div >
        );
    }
}


export default Acquisition = connect()(Acquisition);