import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import ProgressBar from './ProgressBar'
import Audience from './Audience';
import Device from './Device'
import Acquisition from './Acquisition';
import Behavior from './Behavior'
import Footer from './Footer.js'

class Questions extends Component {

    componentDidUpdate(prevProps) {
        if (this.props.activePage !== prevProps.activePage) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return (
            <div>
                <ProgressBar />

                {this.props.activePage === "audience" &&
                    <Audience />
                }
                {this.props.activePage === "device" &&
                    <Device />
                }
                {this.props.activePage === "acquisition" &&
                    <Acquisition />
                }
                {this.props.activePage === "behavior" &&
                    <Behavior />
                }

                <Footer />
            </div>
        );
    }
}

export default Questions = connect((state) => {
    return {
        activePage: state.data.activePage
    }
})(Questions);