import React, { Component } from 'react';
import { connect } from 'react-redux';
import './home.css';
import { changeActivePage } from './redux/general/data'
import Questions from './components/Questions'
import Results from './components/Results'
import SignUp from './components/SignUp'
import Success from './components/Success'


class Home extends Component {
    constructor(props) {
        super(props)

        this.nextPage = this.nextPage.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.activePage !== prevProps.activePage) {
            window.scrollTo(0, 0)
        }
    }

    nextPage() {
        this.props.dispatch(changeActivePage("audience"))
    }

    render() {
        return (
            <div className="page-wrapper">
                {this.props.activePage === "home" &&
                    <div className="column align-center">
                             <img className='logo' src="/logo.png"></img>
                        <div className="spacer">
                            <h1>Improve your Online Sales</h1>
                        </div>
                        <h3>Google Analytics is confusing!</h3>
                        <h3>This tool is your free Digital marketing manager</h3>
                        <h3 className="nextLine">based on a decade of experience in digital marketing and SEO</h3>

                        <h3>Enter your data and receive <span className="bold">instant actions</span> to improve your online sales</h3>

                        <div className="row home-buttons-container">
                            <a className="main-btn" href="mailto:me@maddyking44.com?subject=Digital Marketing services&body=Hi Maddy! I'd like to hire you to give me insights and actions from my Google Analytics. I understand your base rates start at $1500/day, and this may be 1-5 days work depending on how well set up my Google Analytics are." target="_blank">Do it for me</a>
                            <button className="main-btn" onClick={() => this.nextPage()}>Show me how</button>
                        </div>
                        <br/>
                        <br/>
                        <p>All your data is protected and belongs to you: none of it gets sent to us</p>
                    </div>}
                {(this.props.activePage === "audience" ||
                    this.props.activePage === "device" ||
                    this.props.activePage === "acquisition" ||
                    this.props.activePage === "behavior") &&
                    <Questions />}

                {this.props.activePage === "results" &&
                    <Results />
                }

                {this.props.activePage === "signUp" &&
                    <SignUp />
                }
                {this.props.activePage === "success" &&
                    <Success />
                }
            <a href='https://github.com/bsides44/wizness' target='_blank' rel='noopener noreferrer'><img id="gitButton" src="./github.png"/></a>
            </div>

        );
    }
}


export default Home = connect((state) => {
    return {
        activePage: state.data.activePage
    }
})(Home);