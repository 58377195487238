import React, { Component } from '../../node_modules/react';
import { connect } from '../../node_modules/react-redux';
import '../home.css';
import { changeActivePage, setBackPage } from '../redux/general/data'
import BehaviorResults from './BehaviorResults'
import behaviorflow from '../images/behaviorflow.gif'
import startingpages from '../images/startingpages.gif'
import firstinteraction from '../images/firstinteraction.gif'
import secondinteraction from '../images/secondinteraction.gif'

class Behavior extends Component {
    constructor(props) {
        super(props)
        this.analysisSection = React.createRef()

        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.renderBehavior = this.renderBehavior.bind(this)
        this.showGif = this.showGif.bind(this)
        this.hideGif = this.hideGif.bind(this)
        this.state = {}
    }

    componentDidMount() {
        this.setState({
            analysisSectionActive: false,
        })
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit(event);
        }
    }

    handleChange(e) {
        e.persist()
        let value = e.target.value
        if (value === "/") value = "Home"

        var stateObject = function () {
            let returnObj = {};
            returnObj[this.target.name] = value;
            return returnObj;
        }.bind(e)();

        this.setState(stateObject);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (Object.keys(this.state).length <= 9) {
            return this.setState({
                analysisSectionActive: false,
                submitError: true
            })
        }

        this.setState({
            analysisSectionActive: true,
            submitError: false,
        })

        this.hideGif()

        window.setTimeout(() => {
            this.scrollToAnalysis()
        }, 100);
    }

    scrollToAnalysis = () => window.scrollTo({
        top: this.analysisSection.current.offsetTop,
        left: 0,
        behavior: 'smooth'
    })

    nextPage() {
        this.props.dispatch(setBackPage("behavior"))
        this.props.dispatch(changeActivePage("results"))
    }

    previousPage() {
        this.props.dispatch(changeActivePage("acquisition"))
    }

    showGif(gif, section) {
        let source = behaviorflow
        if (gif === "behaviorflow") source = behaviorflow
        if (gif === "startingpages") source = startingpages
        if (gif === "firstinteraction") source = firstinteraction
        if (gif === "secondinteraction") source = secondinteraction

        this.setState({
            showGif: true,
            gifSource: source,
            gifSection: section
        })

        document.body.classList.add("noscroll")
    }

    hideGif() {
        this.setState({
            showGif: false,
        })

        document.body.classList.remove("noscroll")
    }

    largestPageDropoffs(behaviors) {
        if (behaviors) {
            const one = behaviors.behaviorOne.dropoffs
            const two = behaviors.behaviorTwo.dropoffs
            const three = behaviors.behaviorThree.dropoffs

            if (Math.max(one, two, three) == one) return behaviors.behaviorOne
            if (Math.max(one, two, three) == two) return behaviors.behaviorTwo
            if (Math.max(one, two, three) == three) return behaviors.behaviorThree
        }
    }

    renderBehavior() {
        const behaviors = {
            behaviorOne: {
                page: this.state.behaviorFlowOne,
                through: this.state.behaviorOneThrough,
                dropoffs: this.state.behaviorOneDropoffs,
            },
            behaviorTwo: {
                page: this.state.behaviorFlowTwo,
                through: this.state.behaviorTwoThrough,
                dropoffs: this.state.behaviorTwoDropoffs,
            },
            behaviorThree: {
                page: this.state.behaviorFlowThree,
                through: this.state.behaviorThreeThrough,
                dropoffs: this.state.behaviorThreeDropoffs,
            }
        }

        return <div>
            {this.state.behaviorThreeDropoffs && <div>
                <p>
                    The majority of your users land on your <span className="result">{this.state.behaviorFlowOne}</span> page. They go from there to the <span className="result">{this.state.behaviorFlowTwo}</span> page, then head to the <span className="result">{this.state.behaviorFlowThree}</span> page.
                </p>
                <p>
                    Your <span className="result">{this.largestPageDropoffs(behaviors).page}</span> page loses the highest number of visitors. Unless this is your 'Contact' or 'Successful purchase' page, this is a problem. We want to keep people on your site to get them to your goal.
                </p>
                <h3 className="actions">Actions for you:</h3>
                <p>
                    Your <span className="result">{this.largestPageDropoffs(behaviors).page}</span> page lost <span className="result">{this.largestPageDropoffs(behaviors).dropoffs}</span> visitors last month. Let's fix that.
                </p>
                <p>Review your <span className="result">{this.largestPageDropoffs(behaviors).page}</span> page.</p>
                <ul>
                    <li>Is the page's purpose clearly stated in the first screenview?</li>
                    <li>Have you thought about what the user is looking for when they come to this page?</li>
                    <li>Is it immediately clear where users should go to find the information they need?</li>
                    <li>How does this page look on mobile?</li>
                    <li>Can the page design be simpler?</li>
                    <li>Can you reduce the amount of text?</li>
                    <li>Are there videos or sounds autoplaying that you can turn off?</li>
                    <li>Have you tested to see what the page looks like on every browser?</li>
                </ul>
                <p>
                    Your <span className="result">{this.largestPageDropoffs(behaviors).page}</span> page had <span className="result">{this.largestPageDropoffs(behaviors).through}</span> visitors flow through to the next page last month. Let's increase that.
                </p>
                <p>Try putting a 'Call to action' on the first screen of your page that tells users where to go - i.e. a button that says "Buy Now" or "Learn More".
                <br /><button className="show-btn disabled" type="button" title="Coming soon"></button></p>
            </div>}
        </div>
    }

    render() {

        return (
            <div className="view-wrapper">
                <div className="column align-center">
                    <h2>Behavior</h2>
                    <h3>What do users do when they get to your website?</h3>
                </div>
                <form className="GA-form" onSubmit={this.handleSubmit} >
                    <div className="instructions">
                        <div className="row">
                            <div className="sectionOne">
                                <div className="instruction-row">
                                    <p>1. Go to Behavior - Behavior Flow in the left-hand column. Make sure the first column is set to 'Landing Page'.</p>
                                    <button className="play-btn" type="button" onClick={() => this.showGif("behaviorflow", 1)}>{">"}</button>
                                </div>
                                <div className="instruction-row">
                                    <p>2. Look at the first item in second column, Starting Pages. Hover over the item to see its through traffic and drop offs.</p>
                                    <button className="play-btn" type="button" onClick={() => this.showGif("startingpages", 1)}>{">"}</button></div>
                                <label>
                                    Item: (if it's '/' write Home)
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="text" name="behaviorFlowOne" required={true} placeholder="Home" />
                                </label>
                                <label>
                                    Number of through traffic:
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="behaviorOneThrough" required={true} />
                                </label><label>
                                    Number of drop offs:
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="behaviorOneDropoffs" required={true} />
                                </label><br />
                            </div>
                            {this.state.showGif && this.state.gifSection === 1 &&
                                <div className="section gif-container">
                                    <button className="close" onClick={() => this.hideGif()}>X</button>
                                    <img className="gif-player" src={this.state.gifSource} alt="understand google analytics free tool" />
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="sectionTwo">
                                <div className="instruction-row">
                                    <p>3. Look at the first item in the <strong>next</strong> column, 1st Interaction</p>
                                    <button className="play-btn" type="button" onClick={() => this.showGif("firstinteraction", 2)}>{">"}</button>
                                </div>
                                <label>
                                    Item:
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="text" name="behaviorFlowTwo" required={true} placeholder="About" />
                                </label>
                                <label>
                                    Number of through traffic:
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="behaviorTwoThrough" required={true} />
                                </label><label>
                                    Number of drop offs:
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="behaviorTwoDropoffs" required={true} />
                                </label><br />
                            </div>
                            {this.state.showGif && this.state.gifSection === 2 &&
                                <div className="section gif-container">
                                    <button className="close" onClick={() => this.hideGif()}>X</button>
                                    <img className="gif-player" src={this.state.gifSource} alt="understand google analytics free tool" />
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="sectionThree">
                                <div className="instruction-row">
                                    <p>4. Follow that line that comes off that item to the next column, 2nd Interaction. If there are multiple lines, follow the top one.</p>
                                    <button className="play-btn" type="button" onClick={() => this.showGif("secondinteraction", 3)}>{">"}</button>
                                </div>
                                <label>
                                    Item:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="text" name="behaviorFlowThree" required={true} placeholder="Contact" />
                                </label>
                                <label>
                                    Number of through traffic:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="behaviorThreeThrough" required={true} />
                                </label><label>
                                    Number of drop offs:
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="behaviorThreeDropoffs" required={true} />
                                </label>
                            </div>
                            {this.state.showGif && this.state.gifSection === 3 &&
                                <div className="section gif-container">
                                    <button className="close" onClick={() => this.hideGif()}>X</button>
                                    <img className="gif-player" src={this.state.gifSource} alt="understand google analytics free tool" />
                                </div>
                            }
                        </div>
                        {this.state.submitError ?
                            <div className="column">
                                <div className="row submit-btn-container">
                                    {!this.state.analysisSectionActive && <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>}
                                    <div>
                                        <button className="main-btn error" type="submit" value="Submit">Submit</button>

                                    </div>
                                </div>
                                <p className="submitError">Please fill in all fields</p>
                            </div>
                            :
                            <div className="row submit-btn-container">
                                {!this.state.analysisSectionActive && <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>}
                                <button className="main-btn" type="submit" value="Submit">Submit</button>
                            </div>
                        }
                        <div ref={this.analysisSection}>
                            {this.state.analysisSectionActive &&
                                <div className={"analysis-section"}>
                                    <h2>Behavior Analysis:</h2>
                                    <BehaviorResults
                                        {...this.state}
                                    />
                                    <div className="row home-buttons-container">
                                        <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>
                                        <button className="main-btn" onClick={() => this.nextPage()}>Next</button>
                                    </div>
                                </div>}
                        </div>
                    </div>

                </form>
            </div>
        );
    }
}


export default Behavior = connect()(Behavior);