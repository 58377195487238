export const ACTIVE_PAGE = "ACTIVE_PAGE";
export const SET_BACK_PAGE = "SET_BACK_PAGE"
export const SET_USER_NUMBER = "SET_USER_NUMBER"
export const SET_REPORT_GRADE = "SET_REPORT_GRADE"
export const SET_USER_RESULT = "SET_USER_RESULT"
export const SET_USER_OPPORTUNITY = "SET_USER_OPPORTUNITY"
export const SET_MOBILE_NUMBER = "SET_MOBILE_NUMBER"
export const SET_MOBILE_RESULT = "SET_MOBILE_RESULT"
export const SET_MOBILE_OPPORTUNITY = "SET_MOBILE_OPPORTUNITY"
export const SET_TOP_TRAFFIC = "SET_TOP_TRAFFIC"
export const SET_CHANNEL_IMPROVEMENT = "SET_CHANNEL_IMPROVEMENT"
export const SET_CHANNEL_OPPORTUNITY = "SET_CHANNEL_OPPORTUNITY "
export const SET_LANDING_PAGE = "SET_LANDING_PAGE"
export const SET_DROPOFF_PAGE = "SET_DROPOFF_PAGE"
export const SET_BEHAVIOR_OPPORTUNITY = "SET_BEHAVIOR_OPPORTUNITY"


