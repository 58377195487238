import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import { setMobileUserNumber, setMobileResult, setMobileOpportunity } from '../redux/general/data';
import { usersFromPercent } from '../utils/utils';

class DeviceResults extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.setState({
            ...this.props
        })
    }

    componentDidUpdate(prevState) {
        if (this.props !== prevState) {
            this.setState({
                ...this.props
            })
        }
    }

    finalResults(combinedMobile) {
        this.props.dispatch(setMobileUserNumber(combinedMobile))

        let mobileResult = "reasonable"
        let mobileOpp
        if (combinedMobile / (this.props.numberOfUsers + combinedMobile) >= 0.5) mobileResult = "an excellent"; mobileOpp = "try compressing larger images to make your site load even faster on mobile."
        if (combinedMobile / (this.props.numberOfUsers + combinedMobile) >= 0.4) mobileResult = "a good"; mobileOpp = "try removing excess information to make your site look even better on mobile"
        if (combinedMobile / (this.props.numberOfUsers + combinedMobile) >= 0.2) mobileResult = "an insufficient"; mobileOpp = "improve the mobile responsiveness of your site."
        if (combinedMobile / (this.props.numberOfUsers + combinedMobile) >= 0.1) mobileResult = "a poor"; mobileOpp = "improve the load time of your site."

        this.props.dispatch(setMobileResult(mobileResult))
        this.props.dispatch(setMobileOpportunity(mobileOpp))


    }

    render() {
        const combinedMobile = (Number(this.state.mobile) || 0) + (Number(this.state.tablet) || 0)

        this.finalResults(combinedMobile)

        return (
            <div>
                <div>
                    {!!this.state.mobile && <p> <span className="result">{this.state.mobile}</span>% of users came to your site on a mobile phone.</p>}
                    {!!this.state.tablet && <p> <span className="result">{this.state.tablet}</span>% of users came to your site using a tablet.</p>}
                    {(this.state.mobile || this.state.tablet) && <p>In total, that's {usersFromPercent(combinedMobile, this.props.numberOfUsers)} visitors.</p>}
                    <p>In 2017, mobile internet use surpassed computer-based internet for the first time. Today over 52% of internet use occurs via a mobile phone, with numbers rising fairly steadily every year.</p>
                    <p>If people have a negative experience on mobile, they're <a href="https://www.thinkwithgoogle.com/intl/en-gb/advertising-channels/mobile/few-tips-speed-your-mobile-site-and-tools-test-it/" target="_blank" rel="noopener noreferrer">62% less likely</a> to make a purchase with you next time.</p>
                    <p>Since 2018 Google search prefers mobile-friendly sites. Sites that aren't mobile friendly can have their search ranking slashed.</p>
                </div>
                <h3 className="actions">Actions for you:</h3>
                <div>
                    <p>Review your mobile site to ensure mobile visitors have a good experience there.</p>
                    <p>Mobile review checklist:</p>

                    <p><input type="checkbox" />Your website looks good on mobile</p>
                    <p><input type="checkbox" />Text is big enough to read without zooming in</p>
                    <p><input type="checkbox" />User doesn't need to rotate their phone horizontally</p>
                    <p><input type="checkbox" />Customer can access all the buttons and links - they far enough apart so a finger can press just one</p>
                    <p><input type="checkbox" />There are no annoying pop-ups that block access to the site</p>
                    <p><input type="checkbox" />Site does not require Flash. iPhones don't like Flash.</p>
                    <p><input type="checkbox" />Site looks good on both iPhone and Android, phones and tablets, across a range of different browsers and screen sizes</p>
                    <p><input type="checkbox" />Site loads on mobile data (3G, 4G or 5G) in under 3 seconds. More than 3 seconds <a href="https://www.thinkwithgoogle.com/marketing-resources/data-measurement/mobile-page-speed-new-industry-benchmarks/" target="_blank" rel="noopener noreferrer">makes the probability of your visitor bouncing increase 32%</a>.</p>
                    <p><input type="checkbox" />Sales operations and/or contact forms work easily</p>
                    <br />
                    <p>Check whether Google thinks your site is mobile friendly by <a href="https://search.google.com/test/mobile-friendly" target="_blank" rel="noopener noreferrer"> entering the url here.</a></p>
                    <p>If your site is too slow, improve the load time of your mobile site.<br /><button className="show-btn disabled" type="button" title="Coming soon"></button></p>
                    <p>If your site doesn't look good, make your site mobile responsive.<br /><button className="show-btn disabled" type="button" title="Coming soon"></button></p>
                </div>
            </div>
        )
    }


}

export default DeviceResults = connect((state) => {
    return {
        numberOfUsers: state.data.numberOfUsers,
    }
})(DeviceResults);