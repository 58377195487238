import * as actionTypes from "./actionTypes";

const initialState = {
    activePage: 'home',
    backPage: null,
    numberOfUsers: null,
    reportGrade: null,
    userResult: null,
    userOpportunity: null,
    mobileNumber: null,
    mobileResult: null,
    mobileOpportunity: null,
    topTrafficSource: null,
    channelForImprovement: null,
    channelOpportunity: null,
    topLandingPage: null,
    topDropoffPage: null,
    behaviorOpportunity: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.activePage
            };
        case actionTypes.SET_BACK_PAGE:
            return {
                ...state,
                backPage: action.backPage
            };
        case actionTypes.SET_USER_NUMBER:
            return {
                ...state,
                numberOfUsers: action.numberOfUsers
            };
        case actionTypes.SET_REPORT_GRADE:
            return {
                ...state,
                reportGrade: action.grade
            };
        case actionTypes.SET_USER_RESULT:
            return {
                ...state,
                userResult: action.result
            };
        case actionTypes.SET_USER_OPPORTUNITY:
            return {
                ...state,
                userOpportunity: action.opportunity
            };
        case actionTypes.SET_MOBILE_NUMBER:
            return {
                ...state,
                mobileNumber: action.mobileNumber
            };
        case actionTypes.SET_MOBILE_RESULT:
            return {
                ...state,
                mobileResult: action.result
            };
        case actionTypes.SET_MOBILE_OPPORTUNITY:
            return {
                ...state,
                mobileOpportunity: action.opportunity
            };
        case actionTypes.SET_TOP_TRAFFIC:
            return {
                ...state,
                topTrafficSource: action.source
            };
        case actionTypes.SET_CHANNEL_IMPROVEMENT:
            return {
                ...state,
                channelForImprovement: action.channel
            };
        case actionTypes.SET_CHANNEL_OPPORTUNITY:
            return {
                ...state,
                channelOpportunity: action.opportunity
            };
        case actionTypes.SET_LANDING_PAGE:
            return {
                ...state,
                topLandingPage: action.page
            };
        case actionTypes.SET_DROPOFF_PAGE:
            return {
                ...state,
                topDropoffPage: action.page
            };
        case actionTypes.SET_BEHAVIOR_OPPORTUNITY:
            return {
                ...state,
                behaviorOpportunity: action.opportunity
            };
        default:
            return {
                ...state
            }
    }
}

