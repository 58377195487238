import React, { Component } from '../../node_modules/react';
import { goodScore, goodScoreInverse, reportCard, inverseReportCard } from '../utils/utils';
import { connect } from '../../node_modules/react-redux';
import { changeActivePage, setUserNumber } from '../redux/general/data';
import AudienceResults from './AudienceResults'
import login from '../images/login.gif'
import audience from '../images/audience-overview.gif'
import lastMonth from '../images/last-month.gif'
import returning from '../images/returning.gif'
import bounce from '../images/bounce.gif'
import pagespersess from '../images/pagespersess.gif'
import sessduration from '../images/sessduration.gif'
import sessperuser from '../images/sessperuser.gif'
import users from '../images/users.gif'


class Audience extends Component {
    constructor(props) {
        super(props)
        this.analysisSection = React.createRef()

        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.showGif = this.showGif.bind(this)
        this.hideGif = this.hideGif.bind(this)
        // this.renderAudience = this.renderAudience.bind(this)

        this.state = {}
    }

    componentDidMount() {
        this.setState({
            analysisSectionActive: false,
        })
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit(event);
        }
    }

    handleChange(e) {
        e.persist()

        var stateObject = function () {
            let returnObj = {};
            returnObj[this.target.name] = this.target.value;
            return returnObj;
        }.bind(e)();

        this.setState(stateObject);

        if (this.state.users) this.setState({ submitError: false })
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.state.users) return this.setState({ submitError: true, analysisSectionActive: false })

        this.props.dispatch(setUserNumber(this.state.users))

        this.setState({
            submitError: false,
            analysisSectionActive: true,
        })

        this.hideGif()

        window.setTimeout(() => {
            this.scrollToAnalysis()
        }, 100);
    }

    scrollToAnalysis = () => window.scrollTo({
        top: this.analysisSection.current.offsetTop,
        left: 0,
        behavior: 'smooth'
    })

    nextPage() {
        this.props.dispatch(changeActivePage("device"))
    }

    previousPage() {
        this.props.dispatch(changeActivePage("home"))
    }

    showGif(gif) {
        let source = login
        if (gif === "login") {
            source = login
            this.setState({
                turnHighlightOff: true
            })
        }
        if (gif === "audience") source = audience
        if (gif === "last-month") source = lastMonth
        if (gif === "bounce") source = bounce
        if (gif === "pagespersess") source = pagespersess
        if (gif === "sessduration") source = sessduration
        if (gif === "sessperuser") source = sessperuser
        if (gif === "users") source = users
        if (gif === "returning") source = returning


        this.setState({
            showGif: true,
            gifSource: source,
        })

        document.body.classList.add("noscroll")
    }

    hideGif() {
        this.setState({
            showGif: false,
        })
        document.body.classList.remove("noscroll")
    }

    highlightIsActive() {
        return this.state.turnHighlightOff ? "" : "first"
    }

    showLabelIsActive() {
        return this.state.turnHighlightOff ? "show-label-off" : ""
    }

    renderAudience() {

        let reportScore = 3
        let reportGrade = "C"
        reportScore = (reportCard(1.5, 1, this.state.sessionsPerUser) +
            reportCard(3, 2, this.state.sessionDuration) +
            reportCard(3, 2, this.state.pagesPerSession) +
            inverseReportCard(40, 70, this.state.bounceRate) +
            reportCard(50, 30, this.state.returningVisitors)) / 5
        if (Math.round(reportScore) === 5) reportGrade = "F"
        if (Math.round(reportScore) === 4) reportGrade = "D"
        if (Math.round(reportScore) === 3) reportGrade = "C"
        if (Math.round(reportScore) === 2) reportGrade = "B"
        if (Math.round(reportScore) === 1) reportGrade = "A"

        return <div>
            {Object.keys(this.state).length >= 4 && reportGrade &&
                <div className="column align-center">
                    <h3>Your grade:</h3>
                    <h1 className={"reportCard score-" + reportGrade}>{reportGrade}</h1>
                </div>
            }

            {this.state.users && <p>In the past month, <span className="result">{this.state.users}</span> people have visited your website. </p>}

            {this.state.sessionsPerUser && <p>On average, each user has visited your website <span className="result">{this.state.sessionsPerUser}</span> times. {goodScore(1.5, 1, this.state.sessionsPerUser)}</p>}

            {this.state.sessionDuration && <p>They stayed for an average of <span className="result">{this.state.sessionDuration}</span> minutes. Unless they are arriving and immediately buying, you generally want visitors (especially new visitors) to stay for several minutes. {goodScore(3, 2, this.state.sessionDuration)}</p>}

            {this.state.pagesPerSession && <p>They visited <span className="result">{this.state.pagesPerSession}</span> pages. Unless you have a one-page site, you generally need people to visit a couple of pages to go through your sales funnel and convert to a buyer. {goodScore(3, 2, this.state.pagesPerSession)}</p>}

            {this.state.bounceRate && <p>For <span className="result">{this.state.bounceRate}%</span> of these visits, the user bounced as soon as they arrived on your site. This means they left before they explored a new page in the site. {goodScoreInverse(40, 70, this.state.bounceRate)}</p>}

            {this.state.returningVisitors && this.state.users && <p><span className="result">{this.state.users - this.state.returningVisitors}</span> of your users are new and <span className="result">{this.state.returningVisitors}</span> have been there before. It's generally better for business if you have a fairly high number of returning visitors - as these are your top customers. {goodScore(50, 30, this.state.returningVisitors)}</p>
            }

            <h3 className="actions">Actions for you:</h3>
            {this.state.bounceRate || this.state.returningVisitors || this.state.sessionDuration || this.state.pagesPerSession ? <div>
                {this.state.bounceRate > 41 &&
                    <div>
                        <p>You <span className="result">{this.state.bounceRate > 70 && <span className="bad">drastically</span>}</span> need to improve your <span className="result">bounce rate</span>. High bounce rates occur when the user doesn't find what they're looking for in the first 2 seconds of landing on your website. They're calculated as a user landing on your site and not moving on to another page. <br />
                        Try putting a Call To Action on your landing page, i.e. 'Buy Now' or 'Learn More', that tells your users what to do when they arrive.<br /> <button className="show-btn disabled" type="button" title="Coming soon"></button>
                            <br />
                        If you have a <span className="result">single page site</span>, you need to add manual Analytics triggers to track user behavior, otherwise Analytics will count everything as a bounce.<br />
                            <button className="show-btn disabled" type="button" title="Coming soon"></button></p>

                    </div>}

                {this.state.returningVisitors && <p>
                    To <span className="result">increase your number of returning visitors</span>, try adding a form for people to sign up to your mailing list. It's generally best to offer to give away something free in exchange for the sign up, i.e. 'Sign up to receive your free tutorial!' Once you have sign ups, you can prompt visitors to return to your site via your email newsletter.
                   <br /> <button className="show-btn disabled" type="button" title="Coming soon"></button>
                </p>
                }

                {this.state.sessionDuration <= 2 && <p>
                    To <span className="result">increase the amount of time </span>people spend on your site, ask someone who's never been there what information is missing when they arrive. Try adding a short video or animation to give the visitor the information they're missing. Keep it under 30 seconds, and you can reuse the clip as a social media ad.<br /> <button className="show-btn disabled" type="button" title="Coming soon"></button>
                </p>}
                {this.state.pagesPerSession <= 2 && <p>
                    To <span className="result">increase the amount of pages they look at</span>, try defining your sales funnel - the 1-3 pages you want them to look at as they decide whether to meet your goal (usually to buy something or contact you). On each of these pages, add a button within the page that takes them to the next page in the funnel.<br /><button className="show-btn disabled" type="button" title="Coming soon"></button>
                </p>}
            </div> :
                Object.values(this.state).length <= 3 ? <p>No actions for you - Please input more data.</p> :
                    <p> No actions for you - let's keep looking!</p>}
            <p></p>
        </div>
    }

    render() {
        return (
            <div className="view-wrapper">
                <div className="column align-center">
                    <h1>Audience</h1>
                    <h3>Who is coming to your website?</h3>
                </div>
                <div className="row">
                    <form className="GA-form" onSubmit={this.handleSubmit} >
                        <div className="instructions">
                            <div className="instruction-row">
                                <p>1. <a href="http://analytics.google.com" target="_blank" rel="noopener noreferrer">Log in to GA</a></p>
                                <div className="row highlight-btn">
                                    <button className={"inv-btn show-me-label " + this.showLabelIsActive()} type="button" onClick={() => this.showGif("login")}>Show me</button>
                                    <button className={"play-btn " + this.highlightIsActive()} type="button" onClick={() => this.showGif("login")}>{">"}</button>
                                </div>
                            </div>
                            <div className="instruction-row">
                                <p>2. Go to Audience - Overview in the left-hand column</p>
                                <button className="play-btn" type="button" onClick={() => this.showGif("audience")}>{">"}</button>
                            </div>
                            <div className="instruction-row">
                                <p>3. Set your timeframe to the last month</p>
                                <button className="play-btn" type="button" onClick={() => this.showGif("last-month")}>{">"}</button>
                            </div>
                            <div className="form-row">
                                <div className="label-btn-container">
                                    Users:
                                <button className="play-btn" type="button" onClick={() => this.showGif("users")}>{">"}</button>
                                </div>
                                <label>
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="users" required={true} />
                                </label>
                            </div>
                            {this.state.submitError && <p className="submitError">Number of users required</p>}
                            <br />
                            <div className="form-row">
                                <div className="label-btn-container">
                                    Number of sessions per user:
                                <button className="play-btn" type="button" onClick={() => this.showGif("sessperuser")}>{">"}</button>
                                </div>
                                <label>
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="sessionsPerUser" />
                                </label>
                            </div>
                            <div className="form-row">
                                <div className="label-btn-container">
                                    Number of pages per session:
                                <button className="play-btn" type="button" onClick={() => this.showGif("pagespersess")}>{">"}</button>
                                </div>
                                <label>
                                    <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="pagesPerSession" />
                                </label>
                            </div>
                            <div className="form-row-extra align-base">
                                <div className="extra-container align-base">
                                    <div className="label-btn-container">
                                        Average session duration:
                                <button className="play-btn" type="button" onClick={() => this.showGif("sessduration")}>{">"}</button>
                                    </div>
                                    <label>
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="sessionDuration" />
                                    </label></div><p>mins</p>
                            </div>
                            <div className="form-row-extra align-base">
                                <div className="extra-container align-base">
                                    <div className="label-btn-container">
                                        Bounce rate:
                                <button className="play-btn" type="button" onClick={() => this.showGif("bounce")}>{">"}</button>
                                    </div>
                                    <label>
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" step="0.01" name="bounceRate" />
                                    </label>
                                </div>
                                %
                                </div>
                            <div className="form-row-extra align-base">
                                <div className="extra-container align-base">
                                    <div className="label-btn-container">
                                        Returning visitors (number of Users):
                                    <button className="play-btn" type="button" onClick={() => this.showGif("returning")}>{">"}</button>
                                    </div>
                                    <label>
                                        <input onKeyDown={this.onKeyDown} onChange={this.handleChange} type="number" name="returningVisitors" />
                                    </label> </div>users
                                </div><br />
                            {this.state.submitError ?
                                <div className="column">
                                    <div className="row submit-btn-container">
                                        {!this.state.analysisSectionActive && <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>}
                                        <div>
                                            <button className="main-btn error" type="submit" value="Submit">Submit</button>
                                        </div>
                                    </div>
                                    <p className="submitError">Number of users required</p>
                                </div>
                                :
                                <div className="row submit-btn-container">
                                    {!this.state.analysisSectionActive && <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>}
                                    <button id="submit" className="main-btn" type="submit" value="Submit">Submit</button>
                                </div>
                            }
                            <div ref={this.analysisSection}>
                                {this.state.analysisSectionActive &&
                                    <div className="analysis-section" >
                                        <h2>Audience Analysis</h2>
                                        <AudienceResults
                                            {...this.state}
                                        />
                                        <div className="row home-buttons-container">
                                            <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>
                                            <button className="main-btn" onClick={() => this.nextPage()}>Next</button>
                                        </div>
                                    </div>
                                }</div>
                        </div>
                    </form>
                    {this.state.showGif &&
                        <div className="gif-container">
                            <button className="close" onClick={() => this.hideGif()}>X</button>
                            <img className="gif-player" src={this.state.gifSource} alt="understand google analytics free tool" />
                        </div>
                    }
                </div>
            </div >
        );
    }
}


export default Audience = connect()(Audience);