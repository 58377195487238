import React, { Component } from 'react';

class NotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        return (
            <div>
                <h1>Whoops! You got lost on the way.</h1>
            </div>
        );
    }
}


export default NotFound;