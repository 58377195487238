import React from 'react';

export function goodScore(goodScore, averageScore, score) {
    if (score >= goodScore) return <span className="score">This is a <span className="good">good</span> score.</span>
    if (score >= averageScore) return <span className="score">This is a <span className="reasonable">reasonable</span> score.</span>
    return <span className="score">This is <span className="bad">not</span> a good score.</span>
}

export function goodScoreInverse(goodScore, averageScore, score) {
    if (score <= goodScore) return <span className="score">This is a <span className="good">good</span> score.</span>
    if (score <= averageScore) return <span className="score">This is a <span className="reasonable">reasonable</span> score.</span>
    return <span className="score">This is <span className="bad">not</span> a good score.</span>
}

export function reportCard(goodScore, averageScore, score) {
    if (!!score) {
        if (score >= goodScore) return 1
        if (score >= averageScore + ((goodScore - averageScore) / 2)) return 2
        if (score >= averageScore) return 3
        if (score >= averageScore - ((goodScore - averageScore) / 2)) return 4
        return 5
    }
}

export function inverseReportCard(goodScore, averageScore, score) {
    if (!!score) {
        if (score <= goodScore) return 1
        if (score <= averageScore - ((goodScore - averageScore) / 2)) return 2
        if (score <= averageScore) return 3
        if (score <= averageScore + ((goodScore - averageScore) / 2)) return 4
        return 5
    }
}

export function usersFromPercent(percent, users) {
    return Math.floor(((percent) / 100) * users)
}


export function arrayToStringConjunctions(array) {
    let items = ""
    if (array && array.length) {
        const count = array.length
        return items = array.map((item, i) => {
            let joiner = ""
            if (i !== count - 1) joiner = (i === count - 2) ? " and " : ", "
            return items.concat(item + joiner)
        })
    }
}