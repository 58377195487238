import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import { changeActivePage } from '../redux/general/data';

class Success extends Component {
    constructor(props) {
        super(props);

        this.goHome = this.goHome.bind(this)
    }

    goHome() {
        this.props.dispatch(changeActivePage('home'))
    }
    previousPage() {
        this.props.dispatch(changeActivePage('signUp'))
    }

    render() {
        return (
            <div className="column align-center">
                <h2>Thanks for signing up!</h2>
                <button className="main-btn" onClick={() => this.goHome()}>Home</button>
                <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>
                <p style={{ paddingTop: "50px" }}><a href="https://maddyking44.com" target="_blank" rel="noopener noreferrer">maddyking44.com</a></p>
            </div>
        );
    }
}

export default Success = connect()(Success);