import React, { Component } from 'react';
import { connect } from '../../node_modules/react-redux';
import { changeActivePage } from '../redux/general/data';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.signUp = React.createRef()

        this.handleChange = this.handleChange.bind(this)
        this.previousPage = this.previousPage.bind(this)

        this.state = {

        }
    }

    scrollToForm = () => window.scrollTo({
        top: this.signUp.current.offsetTop,
        left: 0,
        behavior: 'smooth'
    })

    handleChange(e) {
        e.persist()

        var stateObject = function () {
            let returnObj = {};
            returnObj[this.target.name] = this.target.value;
            return returnObj;
        }.bind(e)();

        this.setState(stateObject);
    }

    handleSubmit(event) {
        event.preventDefault();

        let url = 'https://us-central1-wizness.cloudfunctions.net/addUser'
        // let testurl = 'http://localhost:5001/wizness/us-central1/addUser'

        const body = {}
        if (this.state.name) body.name = this.state.name
        if (this.state.email) body.email = this.state.email
        if (this.state.company) body.company = this.state.company

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };
        fetch(url, requestOptions)
            .then(response => {
                console.log('response', response)
                const data = response;

                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    console.log('error status:', error)
                    return Promise.reject(error);
                }
            }).catch(error => {
                console.log('error:', error);
            });

        this.props.dispatch(changeActivePage('success'))
    }
    previousPage() {
        this.props.dispatch(changeActivePage(this.props.backPage))
    }

    render() {
        return (
            <div className="column align-center">
                <div className="column align-center">
                    <img className='logo' src="/logo.png"></img>
                    <h1>Sign up to the mailing list</h1>
                    <span className="spacer"><button className="main-btn" onClick={() => this.scrollToForm()}>Yes!</button></span>
                    <h3>Receive free bonus content on:</h3>
                    <div className="features text-center">
                        <p>Advanced level insights</p>
                        <p>Tracking your progress over time</p>
                        <p>More features to help you level up your marketing</p>
                    </div>
                </div>
                <div className="signup" ref={this.signUp}>
                    <h3 className="text-center">Sign up now:</h3>
                    <form className="signUp-form GA-form column align-center" onSubmit={this.handleSubmit.bind(this)}>
                        <label>
                            <input onChange={this.handleChange} type="text" name="name" required={true} placeholder="Name" />
                            <input onChange={this.handleChange} type="text" name="company" placeholder="Company" />
                        </label>
                        <label>
                            <input onChange={this.handleChange} type="email" name="email" required={true} placeholder="Email" />
                        </label>
                        <div className="column">
                            <p className="row align-center"><input type="checkbox" required={true} />I'm ok with being contacted</p>
                            <button className="main-btn" type="submit" value="Submit">Sign me up</button>
                            <a className="main-btn" href="mailto:me@maddyking44.com?subject=Digital Marketing services&body=Hi Maddy! I'd like to hire you to give me insights and actions from my Google Analytics. I understand your base rates start at $1500/day, and this may be 1-5 days work depending on how well set up my Google Analytics are." target="_blank">Do it for me</a>
                        </div>
                    </form>
                    <div className="row justify-center">
                        <button className="main-btn back" onClick={() => this.previousPage()}>Back</button>
                    </div>
                </div>

            </div>);
    }
}

export default SignUp = connect((state) => {
    return {
        backPage: state.data.backPage
    }
})(SignUp);
